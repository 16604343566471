const columns = [
  {
    label: "日期",
    prop: "date",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: "left",
  },
  {
    label: "达人昵称(英文)",
    prop: "creator_handle",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "达人昵称(原文)",
    prop: "creator_name",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "成交金额($)",
    prop: "gmv_amount",
    align: "center",
    width: 100,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "成交订单数",
    prop: "order_cnt",
    align: "center",
    width: 100,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "成交商品件数",
    prop: "sales",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "预估佣金收入(฿)",
    prop: "est_commission_amount",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "直播 CTR(%)",
    prop: "live_ctr",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "直播 CO 率(%)",
    prop: "live_c_o",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "直播 RPM(฿)",
    prop: "live_gpm_amount",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "直播成交金额(฿)",
    prop: "amount_delimited_sum",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "直播成交订单数",
    prop: "paid_order_cnt_sum",
    align: "center",
    width: 130,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "直播成交商品件数",
    prop: "live_item_sold_cnt_sum",
    align: "center",
    width: 140,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "视频成交订单数",
    prop: "video_order_cnt",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "视频 CTR(%)",
    prop: "video_ctr",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "视频 CO 率(%)",
    prop: "video_c_o",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "视频 RPM(฿)",
    prop: "video_gpm_amount",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "视频成交金额",
    prop: "amount_sum",
    align: "center",
    width: 120,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
  {
    label: "视频成交商品件数",
    prop: "video_item_sold_cnt_sum",
    align: "center",
    width: 140,
    tooltip: "",
    sortable: false,
    fixed: false,
  },
];
const reportCols = [
  {
    label: "达人信息",
    prop: "name",
    align: "left",
    width: 200,
    tooltip: "",
    sortable: false,
    fixed: "left",
  },
  {
    label: "直播时长(H)",
    prop: "duration_hour",
    align: "center",
    width: 150,
    tooltip: "达人直播的总小时数",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "观看人数",
    prop: "total_views_unique_cnt_sum",
    align: "center",
    width: 150,
    tooltip: "直播观看的总人数",
    sortable: "custom",
    fixed: false,
    toThunsands: 0,
  },
  {
    label: "总GMV($)",
    prop: "gmv_amount_sum",
    align: "center",
    width: 150,
    tooltip: "统计周期内，绑定达人的闭环订单收入",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "直播GMV($)",
    prop: "amount_delimited_sum",
    align: "center",
    width: 150,
    tooltip: "为达人直播产生的成交金额",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "TAP GMV($)",
    prop: "estimated_commission_amount_sum",
    align: "center",
    width: 170,
    tooltip: "达人TAP 预估佣金总额",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "直播小时 GMV($)",
    prop: "gmv_hour",
    align: "center",
    width: 180,
    tooltip: "直播 GMV / 直播时长",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "商品平均分佣比例(%)",
    prop: "avg_commission_rate",
    align: "center",
    width: 220,
    tooltip: "创作者交易数据中的佣金率的和/条数",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "MCN分佣比例(%)",
    prop: "commission_proportion",
    align: "center",
    width: 200,
    tooltip: "MCN 分佣比例",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "达人预估收益($)",
    prop: "est_commission_amount_sum",
    align: "center",
    width: 200,
    tooltip: "达人预估收益",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "预估MCN佣金($)",
    prop: "estimated_mcn_commission",
    align: "center",
    width: 200,
    tooltip: "预估 MCN 佣金",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "预估TAP佣金($)",
    prop: "tap_estimated_commission_amount_sum",
    align: "center",
    width: 200,
    tooltip: "公司获得的预估收益",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "实时TAP佣金($)",
    prop: "tap_actual_commission_amount_sum",
    align: "center",
    width: 200,
    tooltip: "公司获得的实际收益",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
  {
    label: "总佣金($)",
    prop: "total_commission",
    align: "center",
    width: 160,
    tooltip: "预估 MCN 佣金+预估 TAP 佣金",
    sortable: "custom",
    fixed: false,
    toThunsands: 2,
  },
];
export default {
  columns,
  reportCols,
};
